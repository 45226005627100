html {
  font-family: 'Open Sans', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  /* For sticky footer expirements: height:100%; */
}

body {
  margin: 0;
  /* For sticky footer expirements: height:100%; */
}

#root {
  /* For sticky footer expirements: height:100%; */
  /* background-color: #303030; */
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

@font-face {
  font-family: "Michelin-Black";
  src: url("./styles/font/Michelin-Black.otf") format('opentype');
}
@font-face {
  font-family: "Michelin-Bold";
  src: url("./styles/font/Michelin-Bold.otf") format('opentype');
}
@font-face {
  font-family: "Michelin-Light";
  src: url("./styles/font/Michelin-Light.otf") format('opentype');
}
@font-face {
  font-family: "Michelin-Regular";
  src: url("./styles/font/Michelin-Regular.otf") format('opentype');
}
@font-face {
  font-family: "Michelin-SemiBold";
  src: url("./styles/font/Michelin-SemiBold.otf") format('opentype');
}
